// @ts-ignore
import yaml from "../../data/digital-advertising.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import { graphql } from "gatsby"
import LayoutContainer from "../../components/layout/layout-container"
import { PORTRAIT_BODY_TEXT_ALIGNMNENTS } from "../../components/child-mappable/portrait"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"

class DigitalAdvertising extends React.Component {
  render() {
    validatePageYamlData(yaml)

    const images = this.props.data

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <PortraitArrayWithTitle
          data={yaml.works_in_similar_way}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <PortraitArrayWithTitle data={yaml.special_power} />
        <PortraitArrayWithTitle
          data={yaml.target_an_audience}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <PortraitArrayWithTitle
          data={yaml.consider_personalising}
          images={images}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <PortraitArrayWithTitle
          data={yaml.approach_to_evaluation}
          options={{
            portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
          }}
        />
        <PortraitArrayWithTitle
          data={yaml.advertising_going_digital}
          images={images}
        />
      </LayoutContainer>
    )
  }
}

export default DigitalAdvertising

export const query = graphql`
  query {
    digital_advertising_diagram: file(
      relativePath: {
        eq: "digital-advertising/digital_advertising_diagram.png"
      }
    ) {
      ...unsizedFluidImage
    }
    personalisation_diagram: file(
      relativePath: { eq: "digital-advertising/personalisation_diagram.png" }
    ) {
      ...unsizedFluidImage
    }
    digital_poster: file(
      relativePath: { eq: "digital-advertising/digital_poster.png" }
    ) {
      ...fixedHeight50
    }
    spotify_logo: file(
      relativePath: { eq: "digital-advertising/spotify_logo.png" }
    ) {
      ...fixedHeight50
    }
    nascar_logo: file(
      relativePath: { eq: "digital-advertising/nascar_logo.png" }
    ) {
      ...fixedHeight50
    }
  }
`
